import React from "react"
import Markdown from "markdown-to-jsx"

import PageTemplate from "../templates/pageTemplate"

import data from "../../content/pages/privacy.yml"

const PrivacyPage = () => {
  const { content, title } = data

  return (
    <PageTemplate title={title}>
      <Markdown>{content}</Markdown>
    </PageTemplate>
  )
}

export default PrivacyPage
